body {
    background: #151515;
    padding: 0;
    margin: 0;
    color: gray;
}

#logo {
    max-height: 100px;
}

@media only screen and (max-width: 400px) {
    #connectButton {
        min-width: 80px;
    }
    
}